import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterLink } from '@angular/router';
import { RoleService } from '../../services/role.service';
import { map, Observable, startWith } from 'rxjs';
import { Role } from '../../interfaces/role';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ValidationError } from '../../interfaces/validation-error';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { SEOService } from '../../services/seo.service';
import { LoginRequest } from '../../interfaces/login-request';

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-register',
  standalone: true,
  providers: [
    DatePipe,
    MatDatepickerModule,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { ShowError: true, displayDefaultIndicatorType: false },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' },
    },
  ],
  imports: [
    MatInputModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    RouterLink,
    FormsModule,
    MatSelectModule,
    MatIconModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatCheckboxModule,
    AsyncPipe,
    CommonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})
export class RegisterComponent implements OnInit, OnDestroy {
  roleService = inject(RoleService);
  authService = inject(AuthService);
  seoService = inject(SEOService);
  matSnackbar = inject(MatSnackBar);
  roles$!: Observable<Role[]>;
  fb = inject(FormBuilder);
  firstForm!: FormGroup;
  secondForm!: FormGroup;
  thirdForm!: FormGroup;

  filteredPaesi!: Observable<string[]>;
  filteredProvincie!: Observable<string[]>;

  router = inject(Router);
  confirmPasswordHide: boolean = true;
  passwordHide: boolean = true;
  errors!: ValidationError[];

  constructor(
    private ref: MatDialogRef<RegisterComponent>,
    private _formBuilder: FormBuilder,
    private injector: Injector,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private dateAdapter: DateAdapter<Date>,
    @Inject(MAT_DIALOG_DATA) public isDialog: boolean
  ) {
    this.dateAdapter.setLocale('it');
  }

  ngOnInit(): void {
    this.firstForm = this._formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
        acceptUserAgreement: ['', Validators.requiredTrue],
      },
      {
        validators: [
          this.passwordMatchValidator,
          this.passwordUpperCase,
          this.passwordminlenght,
        ],
      }
    );

    this.secondForm = this._formBuilder.group({
      CodFiscale: ['', Validators.required],
      Paese: ['', Validators.required],
      Provincia: ['', Validators.required],
      Via: ['', Validators.required],
      Cap: ['', [Validators.required, Validators.maxLength(5)]],
      Comune: ['', Validators.required],
    });

    this.thirdForm = this._formBuilder.group({
      Nome: ['', Validators.required],
      Cognome: ['', Validators.required],
      Social: [''],
      age: ['', Validators.required],
      PhoneNumber: ['', Validators.required],
    });

    this.filteredProvincie = this.secondForm.controls[
      'Provincia'
    ].valueChanges.pipe(
      startWith(''),
      map((value) => this._filterProvincie(value || ''))
    );

    this.filteredPaesi = this.secondForm.controls['Paese'].valueChanges.pipe(
      startWith(''),
      map((value) => this._filterPaese(value || ''))
    );
  }

  ngOnDestroy(): void {
    this.seoService.cleanActive();
  }

  register(stepper: MatStepper) {
    if (
      this.firstForm.controls['password'].value ==
      this.firstForm.controls['confirmPassword'].value
    ) {
      const formatDate = this.datePipe.transform(
        this.thirdForm.controls['age'].value,
        'dd/MM/yyyy'
      );
      this.thirdForm.controls['age'].setValue(formatDate);

      const registrationData = {
        ...this.firstForm.value,
        ...this.secondForm.value,
        ...this.thirdForm.value,
      };

      this.authService.register(registrationData).subscribe({
        next: (response) => {
          this.closepopup();

          this.openLogin();
          this.matSnackbar.open(response.message, 'Chiudi', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        },
        error: (response) => {
            this.errors = response!.error.message;
            this.matSnackbar.open(response.error.message, 'Chiudi', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
            stepper.selectedIndex = 0;
        },
        complete: () => console.log('Registrazione con successo'),
      });
    } else {
      this.firstForm.setErrors({ passwordMismatch: true });
    }
  }

  goForward(stepper: MatStepper) {
    var fq = {
      email: this.firstForm.controls['email'].value as string ?? "",
      password: this.firstForm.controls['password'].value as string ?? "",
    };

    this.authService.checkUser(fq).subscribe((res) => {
      if (res.isSuccess) {
        stepper.next();
      } else {
        this.matSnackbar.open(res.message, 'Chiudi', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }

  openLogin() {
    this.closepopup();

    this.Openpopup(true, 'Login', LoginComponent);
  }

  Openpopup(code: any, title: any, component: any) {
    var _popup = this.dialog.open(component, {
      // panelClass: 'all-dialog-start',
      enterAnimationDuration: '1000ms',
      exitAnimationDuration: '500ms',
      disableClose: true,
      closeOnNavigation: true,
      data: {
        title: title,
        code: code,
      },
      injector: this.injector,
    });
    _popup.afterClosed().subscribe((item) => {});
  }

  closepopup() {
    this.ref.close('Chiusura automatica');
  }

  openPrivacy() {
    window.open('/privacy-policy');
  }

  private passwordMatchValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const password = control.get('password')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;

    if (password !== confirmPassword) {
      return { passwordMismatch: true };
    }

    return null;
  }

  private passwordUpperCase(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const password = control.get('password')?.value;

    if (!/[A-Z]/.test(password)) {
      return { uppercase: true };
    }

    return null;
  }

  private passwordminlenght(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const password = control.get('password')?.value;

    if (password.length < 8) {
      return { minlength: true };
    }

    return null;
  }

  private _filterProvincie(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.ProvincieList.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  private _filterPaese(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.PaeseList.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  ProvincieList: string[] = [
    'Agrigento',
    'Alessandria',
    'Ancona',
    'Aosta',
    'Arezzo',
    'Ascoli Piceno',
    'Asti',
    'Avellino',
    'Bari',
    'Barletta-Andria-Trani',
    'Belluno',
    'Benevento',
    'Bergamo',
    'Biella',
    'Bologna',
    'Brescia',
    'Brindisi',
    'Cagliari',
    'Caltanissetta',
    'Campobasso',
    'Caserta',
    'Catania',
    'Catanzaro',
    'Chieti',
    'Como',
    'Cosenza',
    'Cremona',
    'Crotone',
    'Cuneo',
    'Enna',
    'Fermo',
    'Ferrara',
    'Firenze',
    'Foggia',
    'Forlì-Cesena',
    'Frosinone',
    'Genova',
    'Gorizia',
    'Grosseto',
    'Imperia',
    'Isernia',
    'La Spezia',
    "L'Aquila",
    'Latina',
    'Lecce',
    'Lecco',
    'Livorno',
    'Lodi',
    'Lucca',
    'Macerata',
    'Mantova',
    'Massa-Carrara',
    'Matera',
    'Messina',
    'Milano',
    'Modena',
    'Monza e della Brianza',
    'Napoli',
    'Novara',
    'Nuoro',
    'Oristano',
    'Padova',
    'Palermo',
    'Parma',
    'Pavia',
    'Perugia',
    'Pesaro e Urbino',
    'Pescara',
    'Piacenza',
    'Pisa',
    'Pistoia',
    'Pordenone',
    'Potenza',
    'Prato',
    'Ragusa',
    'Ravenna',
    'Reggio Calabria',
    'Reggio Emilia',
    'Rieti',
    'Rimini',
    'Roma',
    'Rovigo',
    'Salerno',
    'Sassari',
    'Savona',
    'Siena',
    'Siracusa',
    'Sondrio',
    'Sud Sardegna',
    'Taranto',
    'Teramo',
    'Terni',
    'Torino',
    'Trapani',
    'Trento',
    'Treviso',
    'Trieste',
    'Udine',
    'Varese',
    'Venezia',
    'Verbano-Cusio-Ossola',
    'Vercelli',
    'Verona',
    'Vibo Valentia',
    'Vicenza',
    'Viterbo',
    'Acquaviva',
    'Borgo Maggiore',
    'Chiesanuova',
    'Città di San Marino',
    'Dogana',
    'Domagnano',
    'Faetano',
    'Fiorentino',
    'Montegiardino',
    'Serravalle',
  ];
  PaeseList: string[] = ['Italia', 'San Marino'];
}

const parseDMY = (s: any) => {
  let [d, m, y] = s.split(/\D/);
  return new Date(y, m - 1, d);
};
