import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { AccountComponent } from '../../pages/account/account.component';
import { SEOService } from '../../services/seo.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MatInputModule,
    RouterLink,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit, OnDestroy {
  authService = inject(AuthService);
  seoService = inject(SEOService);
  matSnackBar = inject(MatSnackBar);
  router = inject(Router);
  hide = true;
  form!: FormGroup;

  constructor(
    private ref: MatDialogRef<LoginComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public isDialog: boolean
  ) {}

  ngOnDestroy(): void {
    this.seoService.cleanActive();
  }

  login() {
    this.authService.login(this.form.value).subscribe({
      next: (response) => {
        this.ref.close('Chiusura automatica');

        this.matSnackBar.open(response.message, 'Chiudi', {
          duration: response.abbonamento ? 3000 : Infinity,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });

        this.ref.close();
      },
      error: (error) => {
        this.matSnackBar.open(error.error.message, 'Chiudi', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      },
    });
  }

  resetPsw() {
    var email = this.form.controls['email'].value;

    if (email === null || email == '') {
      this.form.setErrors({ resetPsw: true });
    } else {
      if (this.form.controls['email'].valid) {
        this.matSnackBar.open(
          'recupero della password inviata per email',
          'Chiudi',
          {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          }
        );

        this.ref.close();
      }
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  closepopup() {
    this.ref.close('Chiusura automatica');
  }
}
